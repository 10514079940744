// utils/paginationUtils.ts

/**
 * Calculates the total number of pages based on pagination data.
 *
 * @param {number} total - The total number of items.
 * @param {number} perPage - The number of items per page.
 * @returns {number} - The calculated total number of pages.
 */
export const calculateTotalPages = (total: number, perPage: number): number => {
  return Math.ceil(total / perPage)
}
