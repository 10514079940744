/**
 * Get the first error message from an errors object.
 *
 * @param {Object} errors - The errors object, typically received in a validation response.
 * @returns {string | null} - The first error message, or null if no errors are present.
 */
export const getFirstErrorMessage = errors => {
  if (errors && Object.keys(errors).length > 0) {
    const firstErrorKey = Object.keys(errors)[0]
    const firstErrorMessage = errors[firstErrorKey][0]
    return firstErrorMessage
  }
  return 'Error desconocido'
}
