export interface Meta {
  current_page: number
  from: number
  last_page: number
  path: string
  per_page: number
  to: number
  total: number
}

export enum StatusFilter {
  ALL = 'all',
  ACTIVE = 'true',
  INACTIVE = 'false',
}
