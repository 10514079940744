import { Vue } from 'vue-property-decorator'

import axios from '@/services/base.service'
import {
  CategoryAdmin,
  CategoryAdminResponse,
} from '@/interfaces/admin/categories/category-admin.interface'
import { AxiosResponse } from 'axios'
import { Meta, StatusFilter } from '@/interfaces/global/paginate.interface'
import { ErrorMessages } from '@/helpers/constants'
import { getFirstErrorMessage } from '@/utils/validation/validation.utils'

const resource = 'admin/categories'

export const getCategory = async (categoryId: number): Promise<any> => {
  try {
    const response: AxiosResponse<{ data: CategoryAdmin }> = await axios.get(
      `${resource}/${categoryId}`
    )

    const { data } = response
    return data.data
  } catch (error) {
    const message = error.response?.data?.message || ErrorMessages.COMMON
    Vue.$toast.error(message)
    throw error
  }
}

/**
 * Fetches all categories through a paginated GET request.
 *
 * @param {number} [page=1] - Page number for pagination (optional, default: 1).
 * @param {string} [status=StatusFilter.ALL] - Status filter for categories (optional, default: 'all').
 * @param {string} [filter=''] - Search filter for category names (optional, default: '').
 * @returns {Promise<{ data: CategoryAdmin[]; meta: Meta }>} - A promise that resolves with category data and meta information.
 * @throws {Error} - If an error occurs during the retrieval of categories.
 *
 * @example
 * // Usage in your code
 * const { data, meta } = await getAllCategories(1, StatusFilter.ACTIVE, 'example-filter');
 */
export const getAllCategories = async (
  page: number = 1,
  status: string = StatusFilter.ALL,
  filter: string = ''
): Promise<{ data: CategoryAdmin[]; meta: Meta }> => {
  try {
    const response: AxiosResponse<CategoryAdminResponse> = await axios.get(
      `${resource}?page=${page}&status=${status}${filter ? `&q=${filter}` : ''}`
    )

    const { data } = response

    return {
      data: data.data,
      meta: data.meta,
    }
  } catch (error) {
    const message = error.response?.data?.message || ErrorMessages.COMMON
    Vue.$toast.error(message)
    throw error
  }
}

/**
 * Toggle the active state of a category by making a PATCH request to update its status.
 *
 * @param {number} categoryId - The ID of the category to toggle.
 * @param {boolean} status - The new status (active or inactive) for the category.
 * @returns {Promise<CategoryAdmin>} - A promise that resolves with the updated category data.
 * @throws {Error} - If an error occurs during the toggle operation.
 */
export const toggleActiveCategory = async (
  categoryId: number,
  status: boolean
): Promise<CategoryAdmin> => {
  try {
    const response: AxiosResponse<{
      data: CategoryAdmin
    }> = await axios.patch(`${resource}/${categoryId}`, { active: status })
    const { data } = response
    return data.data
  } catch (error) {
    const message = error.response?.data?.message || ErrorMessages.COMMON
    Vue.$toast.error(message)
    throw error
  }
}

/**
 * Updates a category using a PUT request.
 *
 * @param {CategoryAdmin} category - The category object to be updated.
 * @returns {Promise<CategoryAdmin>} - A promise that resolves with the updated category data.
 * @throws {Error} - If an error occurs during the update or if the response contains an error message.
 *
 * @example
 * // Usage in your code
 * const updatedCategory = await updateCategory(categoryToUpdate);
 */
export const updateCategory = async (
  category: CategoryAdmin
): Promise<CategoryAdmin> => {
  try {
    const response: AxiosResponse<{
      data: CategoryAdmin
    }> = await axios.put(`${resource}/${category.id}`, category)

    const { data } = response
    return data.data
  } catch (error) {
    const message = error.response?.data?.message || ErrorMessages.COMMON
    Vue.$toast.error(message)
    throw error
  }
}

export const createCategory = async (
  category: CategoryAdmin
): Promise<CategoryAdmin> => {
  try {
    const response: AxiosResponse<{
      data: CategoryAdmin
    }> = await axios.post(`${resource}`, category)

    const { data } = response
    return data.data
  } catch (error) {
    const status = error.response?.status
    let message: string = ''
    if (status == 422) {
      message = getFirstErrorMessage(error.response?.data.errors)
    } else {
      message = error.response?.data?.message || ErrorMessages.COMMON
    }
    console.log(message)

    Vue.$toast.error(message)
    throw error
  }
}
