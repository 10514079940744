
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import { getAllCategories, toggleActiveCategory } from '@/services/categories.service'
import { calculateTotalPages } from '@/utils/pagination/pagination.utils'
import { CategoryAdmin } from '@/interfaces/admin/categories/category-admin.interface'
import { StatusFilter } from '@/interfaces/global/paginate.interface'

@Component({
  components: {
    SellerHeader,
  },
})
export default class ListSellers extends Vue {


  statusFilterOptions: string[] = [
    StatusFilter.ALL,
    StatusFilter.ACTIVE,
    StatusFilter.INACTIVE
  ]
  categories: CategoryAdmin[] = [];
  page: number = 1;
  totalPages: number = 1;
  statusFilter: string = StatusFilter.ALL;
  filter: string = ''

  /**
   * Fetches categories based on the specified page and status filter.
   *
   * @param {number} [page=1] - Page number for pagination (optional, default: 1).
   * @param {string} [status=StatusFilter.ALL] - Status filter for categories (optional, default: 'all').
   * @returns {Promise<void>} - A promise that resolves once categories are fetched and component state is updated.
   */
  async getCategories(page = 1, status: string = StatusFilter.ALL) {
    try {
      const { data, meta } = await getAllCategories(page, status, this.filter);
      this.totalPages = calculateTotalPages(meta.total, meta.per_page);
      this.categories = data;
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }

  /**
   * Filters categories based on the specified page and status filter.
   *
   * @param {number} [page=1] - Page number for pagination (optional, default: 1).
   * @param {string} [statusFilter=StatusFilter.ALL] - Status filter for categories (optional, default: 'all').
   * @returns {Promise<void>} - A promise that resolves once categories are filtered and component state is updated.
   */
  async filterCategories(page = 1, statusFilter: string = StatusFilter.ALL) {
    try {
      this.statusFilter = statusFilter;
      await this.getCategories(page, statusFilter);
    } catch (error) {
      console.error('Error filtering categories:', error);
    }
  }

  /**
   * Toggles the active state of a category.
   *
   * @param {boolean} value - New value for the active state of the category.
   * @param {number} index - Index of the category in the array.
   * @returns {Promise<void>} - A promise that resolves once the category update is complete.
   */
  async toggleActiveCategory(value, index) {
    try {
      const { id } = this.categories[index];
      await toggleActiveCategory(id, value);
      this.categories[index].active = value;
    } catch (error) {
      console.error('Error toggling active state:', error);
    }
  }

  /**
   * Listens for changes in the page property and triggers category fetching accordingly.
   *
   * @param {number} nextPage - The new page number.
   */
  @Watch('page', { immediate: true, deep: true })
  onPageChange(nextPage) {
    this.getCategories(nextPage);
  }

  /**
   * Called when the component is mounted. Initiates the initial fetching of categories.
   */
  mounted() {
    this.getCategories(1, this.statusFilter);
  }
}
